// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  backButton?: {label: string, path: string},
  sx?: mixed,
  description?: string,
  title: string,
};

export const PageHeader = ({
  backButton,
  sx = {},
  title,
  description,
}: PropsType): Node => {
  const navigate = useNavigate();

  return (
    <Box>
      {backButton && (
        <Button onClick={() => navigate(backButton.path)}>
          <Stack direction="row" sx={{alignItems: 'center'}}>
            <Icon
              name={IconNames.ArrowLeft}
              size={16}
              color={styles.backButton.color}
            />
            <Typography sx={styles.backButton}>{backButton.label}</Typography>
          </Stack>
        </Button>
      )}
      <Box sx={{mb: 4}}>
        <Typography variant="h4" sx={{...styles.title, ...sx}}>
          {title}
        </Typography>
        {description && (
          <Typography variant="body" sx={styles.description}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.darkest,
  },
  description: {
    display: {xs: 'none', md: 'block'},
  },
  backButton: {
    color: Theme.colorPalette.blueLight,
    ml: 1,
    textTransform: 'none',
  },
};
