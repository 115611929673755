// @flow
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import {createBlankDocument, getDocumentUrl} from '../../utils';
import {Events} from '@wellstone-solutions/common';

export const useCreateDocumentation = () => {
  const {meStore, sessionDocumentStore, eventStore} = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const createDocumentation = async ({memberId}) => {
    setIsLoading(true);

    try {
      const blankDocument = createBlankDocument({
        memberId,
        staffId: meStore.activeMembership.id,
      });

      const response = await sessionDocumentStore.createSessionDoc(
        blankDocument,
      );

      if (response.isSuccess) {
        eventStore.addEvent(Events.CREATED_SESSION_DOC, {
          session_document_id: response.data.id,
        });
        navigate(getDocumentUrl(memberId, response.data.id));
      } else {
        showAlert(
          'Failed to save the document. Please try again.',
          ALERT_TYPES.ERROR,
        );
      }
    } catch (error) {
      showAlert(
        'An unexpected error occurred. Please try again.',
        ALERT_TYPES.ERROR,
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return {createDocumentation, isLoading};
};
