// @flow
export const ALL_MEMBERS = 'All';
export const DOCUMENT_STATUSES = {
  created: 'created',
  draft: 'draft',
  error: 'error',
  completed: 'completed',
  submitted: 'submitted',
  reverted: 'reverted',
};

export const SESSION_TYPES = [
  {value: 'virtual', label: 'Virtual (TeleHealth)'},
  {value: 'in-person', label: 'In-person'},
  {value: 'phone-call', label: 'Phone Call'},
  {value: 'text', label: 'Text/Instant message'},
];

export const DOCUMENT_STATUS_LOOKUP: {
  [key: string]: {label: string},
} = {
  [DOCUMENT_STATUSES.created]: {
    label: 'Created',
  },
  [DOCUMENT_STATUSES.draft]: {
    label: 'In Progress',
  },
  [DOCUMENT_STATUSES.error]: {
    label: 'Sync Failed',
  },
  [DOCUMENT_STATUSES.completed]: {
    label: 'Finalized',
  },
  [DOCUMENT_STATUSES.submitted]: {
    label: 'Submitted',
  },
  [DOCUMENT_STATUSES.reverted]: {
    label: 'Reverted',
  },
};

export const HIDE_DOCUMENT_FINALIZE_CONFIRM_STORAGE_KEY =
  'hide_session_documentation_finalize_confirmation';

export const DATE_TIME_FORMAT = 'MMM D, YYYY; h:mmA';
export const DATE_FORMAT_LONG = 'MMM D, YYYY';
export const DATE_FORMAT_SLASH = 'MM/DD/YYYY';
export const TIME_FORMAT_12 = 'h:mm A';
export const TIME_FORMAT_24 = 'HH:mm';

export const UNIT_LENGTH = 15;
export const UNIT_ROUNDING_THRESHOLD = 8;

export const PAGE_DESCRIPTION =
  'View and create documents that capture peer support sessions with your members.';
