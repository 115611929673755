// React
import React from 'react';

// Third Party
import Moment from 'react-moment';

// Bulma
import Notification from 'react-bulma-components/lib/components/notification';
import Tag from 'react-bulma-components/lib/components/tag';

import {getCheckinOptions} from 'utils/Charts';

class EventStream extends React.Component {
  state = {
    checkinOptions: null,
  };

  async componentDidMount() {
    const checkinOptions = await getCheckinOptions();
    this.setState({checkinOptions});
  }

  _buildEventMessage = (event, e) => {
    const {checkinOptions} = this.state;
    let quickDate = new Date(event.localDate);
    if (!checkinOptions) {
      return null;
    }
    switch (event.category) {
      case 'user_added_checkin':
        return (
          <div key={e}>
            {!this.props.events[e - 1] ||
            new Date(this.props.events[e - 1].localDate).getDate() !==
              new Date(event.localDate).getDate() ? (
              <Moment
                style={styles.eventDate}
                element="span"
                format="MMM D, YYYY"
                date={quickDate}
              />
            ) : null}
            <Notification style={styles.recentEvent} color="dark">
              <Tag.Group style={styles.eventTags}>
                <Tag style={styles.eventTag}>Checkin</Tag>
                {event.data.states.map((state, s) => {
                  const checkin = checkinOptions.find(
                    (item) => item.value === state,
                  );

                  return checkin ? (
                    <Tag
                      key={s}
                      style={{
                        ...{
                          backgroundColor: checkin.color,
                        },
                        ...styles.moodTag,
                      }}>
                      {checkin.title}
                    </Tag>
                  ) : null;
                })}
              </Tag.Group>
            </Notification>
          </div>
        );
      case 'user_marked_message':
        return (
          <div key={e}>
            {!this.props.events[e - 1] ||
            new Date(this.props.events[e - 1].localDate).getDate() !==
              new Date(event.localDate).getDate() ? (
              <Moment
                style={styles.eventDate}
                element="span"
                format="MMM D, YYYY"
                date={quickDate}
              />
            ) : null}
            <Notification
              style={styles.recentEvent}
              color={
                event.data.message.data.author === this.props.userID
                  ? 'warning'
                  : 'info'
              }>
              <Tag.Group style={styles.eventTags}>
                <Tag style={styles.eventTag}>
                  {event.data.message.data.author === this.props.userID
                    ? 'Concerning Message'
                    : 'Flagged a Message'}
                </Tag>
              </Tag.Group>
              {event.data.message.data.author === this.props.userID ? (
                <span>"{event.data.message.data.content}"</span>
              ) : (
                <span>"{event.data.comment}"</span>
              )}
            </Notification>
          </div>
        );
      case 'user_relapsed':
        return (
          <div key={e}>
            {!this.props.events[e - 1] ||
            new Date(this.props.events[e - 1].localDate).getDate() !==
              new Date(event.localDate).getDate() ? (
              <Moment
                style={styles.eventDate}
                element="span"
                format="MMM D, YYYY"
                date={quickDate}
              />
            ) : null}
            <Notification style={styles.recentEvent} color="danger">
              <Tag.Group style={styles.eventTags}>
                <Tag style={styles.eventTag}>Relapse</Tag>
              </Tag.Group>
              <span>
                <Moment
                  element="span"
                  format="MMM D, YYYY"
                  date={event.data.sober_date}
                />
              </span>
            </Notification>
          </div>
        );
      case 'user_created_message':
        return null;
      case 'user_added_friend':
        return (
          <div key={e}>
            {!this.props.events[e - 1] ||
            new Date(this.props.events[e - 1].localDate).getDate() !==
              new Date(event.localDate).getDate() ? (
              <Moment
                style={styles.eventDate}
                element="span"
                format="MMM D, YYYY"
                date={quickDate}
              />
            ) : null}
            <Notification style={styles.recentEvent} color="link">
              <Tag.Group style={styles.eventTags}>
                <Tag style={styles.eventTag}>Community</Tag>
              </Tag.Group>
              <span>Added person to community</span>
            </Notification>
          </div>
        );
      default:
        return 'Unknown Event';
    }
  };

  render() {
    if (!this.props.events || this.props.events.length < 1) {
      return 'No event history available';
    }
    return (
      <div style={styles.eventsContainer} className="event-stream">
        {this.props.events.map((event, e) => {
          return this._buildEventMessage(event, e);
        })}
      </div>
    );
  }
}
const styles = {
  recentEvent: {
    color: 'white',
    padding: 5,
    marginBottom: 5,
  },
  eventsContainer: {
    height: 440,
    overflowY: 'auto',
  },
  eventTags: {
    display: 'inline-block',
    marginBottom: 0,
  },
  eventDate: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#777',
  },
  eventTag: {
    marginRight: 5,
    marginBottom: 0,
  },
  moodTag: {
    marginRight: '0.25rem',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
    marginLeft: 0,
    fontWeight: 'bold',
    color: 'white',
  },
};
export default EventStream;
