// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Fab,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useTheme} from '@mui/material/styles';
import {useStores} from 'hooks/useStores';
import {MembershipSelect} from 'components/MembershipSelect/MembershipSelect';
import {PageHeader} from 'components/PageHeader';

export const AnnouncementsPageV3 = (): Node => {
  const {appUIStore} = useStores();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
  }, [appUIStore]);

  const handleNewAnnouncement = () => {
    navigate('/announcements/new');
  };

  return (
    <Stack>
      <PageHeader title="Announcements" />
      <Stack direction="row" sx={{justifyContent: 'space-between'}}>
        <Box sx={{width: {xs: '85%', sm: '50%', md: '40%', lg: '30%'}}}>
          <MembershipSelect type="group" onChange={() => {}} />
        </Box>
        <Fab
          variant={!isMobile ? 'extended' : ''}
          sx={isMobile ? {...styles.fab, ...styles.mobileFab} : styles.fab}
          onClick={handleNewAnnouncement}>
          <Icon name={IconNames.Plus} color="#FFF" size={24} />
          {!isMobile && (
            <Typography sx={styles.newText}>New Announcement</Typography>
          )}
        </Fab>
      </Stack>
      <Typography sx={{mt: 4}}>Announcement V3 List Placeholder</Typography>
    </Stack>
  );
};

const styles = {
  fab: {
    backgroundColor: Theme.colorPalette.primary,
    borderRadius: 20,
    alignSelf: 'center',
    '&:hover': {
      background: Theme.colorPalette.primary3,
    },
    padding: '0 20px',
  },
  mobileFab: {minHeight: 40, minWidth: 40, padding: 0},
  newText: {
    ml: 1,
    color: Theme.colorPalette.lightest,
    textTransform: 'none',
  },
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.darkest,
    mb: 4,
  },
};
