// @flow
import React from 'react';
import type {Node} from 'react';
import {PfButton} from 'components/shared/PfButton';
import {useCreateDocumentation} from '../hooks';

type PropTypes = {
  memberId: string,
};

export const CreateSessionDocumentButton = ({memberId}: PropTypes): Node => {
  const {createDocumentation, isLoading} = useCreateDocumentation();

  const handleCreateDocumentation = () => {
    createDocumentation({memberId: memberId});
  };

  return (
    <PfButton
      color="primary"
      outlined
      loading={isLoading}
      onClick={handleCreateDocumentation}>
      Create Documentation
    </PfButton>
  );
};
