// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Typography} from '@wellstone-solutions/web';
import {Link} from 'components/Link';

type PropsType = {
  text: string,
  to: string,
  subText?: string,
};

export const NavigationCell = ({text, subText, to}: PropsType): Node => (
  <Link
    to={to}
    sx={{flex: 1, width: '100%', height: '100%'}}
    onClick={(e) => e.stopPropagation()}>
    <Stack sx={{justifyContent: 'center', height: '100%'}}>
      <Typography
        noWrap
        sx={{
          fontWeight: Theme.typography.weight.bold,
          fontSize: Theme.typography.size.s2,
          color: Theme.colorPalette.link,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}>
        {text}
      </Typography>
      {subText && (
        <Typography
          noWrap
          sx={{
            color: Theme.colorPalette.darkest,
            fontSize: Theme.typography.size.s1,
          }}>
          {subText}
        </Typography>
      )}
    </Stack>
  </Link>
);
