// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Models} from '@wellstone-solutions/common';
import {Box, Stack} from '@wellstone-solutions/web';
import {PagedDataGrid, useOptimisticRows} from 'modules/datagrid';
import {useStores} from 'hooks/useStores';
import {useMemberColumnMap} from '../../hooks';
import {ListHeader} from './ListHeader';
import {ALL_MEMBERS} from '../../constants';
import {ROLES} from 'utils/Permissions';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {ResendInvitation} from './ResendInvitation';

import type {MemberFilterType} from './types';

type ParamsType = {
  role: string,
  program_id?: string,
  memberName?: string,
  lastLogin?: string,
  group?: string,
  endDate?: string,
  pavillioId?: string,
};

const {Member} = Models;

const DEFAULT_PARAMS: MemberFilterType = {
  role: ROLES.patient,
};

const buildParams = (filter: string): ParamsType => {
  return filter !== ALL_MEMBERS
    ? {...DEFAULT_PARAMS, program_id: filter}
    : DEFAULT_PARAMS;
};

export const MemberListV2 = (): Node => {
  const {meStore} = useStores();
  const theme = useTheme();
  const initialFilter = meStore.myPrograms[0]?.id || ALL_MEMBERS;
  const [filter, setFilter] = useState(initialFilter);
  const [params, setParams] = useState(buildParams(initialFilter));
  const [memberToSendEmail, setMemberToSendEmail] = useState(null);
  const navigate = useNavigate();
  const mediumBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
  const {
    optimisticRows,
    addOptimisticRow,
    clearOptimisticRows,
  } = useOptimisticRows();

  const handleResendInvitationComplete = ({success}: {success: boolean}) => {
    if (!memberToSendEmail) return;
    if (success) {
      addOptimisticRow({
        id: memberToSendEmail.id,
        newData: {
          ...memberToSendEmail,
          inviteSent: {
            created: moment().toISOString(),
            modified: moment().toISOString(),
            member: memberToSendEmail.id,
          },
        },
      });
    }
    setMemberToSendEmail(null);
  };

  const columnMap = useMemberColumnMap({
    programId: filter,
    onResendInvitationAction: setMemberToSendEmail,
  });

  const columns = mediumBreakPoint
    ? [columnMap.memberDescription]
    : [
        columnMap.nameLinkGender,
        columnMap.lastOpened,
        columnMap.groupsList,
        columnMap.programEndDate,
      ];

  if (!!meStore.ehrIntegrationOption && !mediumBreakPoint) {
    columns.push(columnMap.ehr);
  }
  columns.push(columnMap.actionsList);

  // Used when program select changes
  const handleFilterChange = (updatedFilter) => {
    const updatedParams = buildParams(updatedFilter);
    setParams(updatedParams);
    setFilter(updatedFilter);
  };

  const handleRowClick = (data) => {
    const memberId = data?.row?.id;
    if (memberId) {
      navigate(`/members/${memberId}`);
    }
  };

  return (
    <>
      <Stack>
        <Box sx={{mb: 2}}>
          <ListHeader filter={filter} setFilter={handleFilterChange} />
        </Box>
        <Box>
          <PagedDataGrid
            label="Member"
            columns={columns}
            params={params}
            dataTransformer={(data) => ({
              rows: data.members.map(Member.toUI),
              total: data.total,
            })}
            initialPageSize={25}
            initialSortField={columns[0].field}
            initialSortOrder="asc"
            isLimitOffset={true}
            url={Member.routes.index(meStore.me.membership.organization.id)}
            disableSelectionOnClick
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-cell': {
                cursor: 'pointer',
              },
            }}
            {...(mediumBreakPoint && {
              headerHeight: 0,
              getRowHeight: () => 100,
              getRowClassName: (params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even',
            })}
            optimisticRows={optimisticRows}
            clearOptimisticRows={clearOptimisticRows}
          />
        </Box>
      </Stack>
      <ResendInvitation
        member={memberToSendEmail}
        open={Boolean(memberToSendEmail)}
        organizationId={meStore.organizationId}
        onComplete={handleResendInvitationComplete}
      />
    </>
  );
};
