// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {useStores} from 'hooks/useStores';
import {PageHeader} from 'components/PageHeader';

export const New = (): Node => {
  const {appUIStore} = useStores();

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
  }, [appUIStore]);

  return (
    <PageHeader
      title="New Announcement"
      backButton={{label: 'Back to Announcements', path: '/announcements'}}
    />
  );
};
