// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  ListItemIcon,
  Icon,
  IconNames,
  ListItemText,
  Stack,
  Progress,
} from '@wellstone-solutions/web';

import {useCreateDocumentation} from 'modules/documentation/hooks';
import type {UIMemberType} from '@wellstone-solutions/common';

type PropsType = {
  member: UIMemberType,
  label?: string,
  children?: string,
  disabled?: boolean,
};

export const CreateDocumentationAction = ({
  member,
  children,
  label,
  disabled = false,
}: PropsType): Node => {
  const {createDocumentation, isLoading} = useCreateDocumentation();

  const handleCreateDocumentation = () => {
    createDocumentation({memberId: member.id});
  };

  return (
    <Stack
      direction="row"
      onClick={() => {
        if (!disabled) handleCreateDocumentation();
      }}
      data-testid="action-create-document">
      <ListItemIcon>
        {isLoading ? (
          <Progress
            size="1.2em"
            color="inherit"
            data-testid="loading-spinner"
          />
        ) : (
          <Icon
            name={IconNames.SubmitDocument}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        )}
      </ListItemIcon>
      <ListItemText sx={styles.label}>Create documentation</ListItemText>
    </Stack>
  );
};

const styles = {
  label: {
    '& .MuiTypography-root': {
      color: Theme.colorPalette.darkest,
    },
  },
};
