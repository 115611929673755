// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Typography, Box} from '@wellstone-solutions/web';
import {Link} from 'components/Link';
import moment from 'moment';
import {getIdentity} from 'utils/Forms';
import type {UIMemberType} from '@wellstone-solutions/common';

const currentGroups = (member) => {
  return (member.groupAdmissions || []).filter((adm) => !adm.discharge_date);
};

type PropsType = {
  member: UIMemberType,
};

export const MemberProfileCell = ({member}: PropsType): Node => {
  const memberURL = `/members/${member.id}`;
  const identity = getIdentity(member.identifiesAs);
  const groups = currentGroups(member).map((group) => group.group?.name);
  const lastLogin = member.lastOpenedApp?.created
    ? moment(member.lastOpenedApp.created).toDate()
    : null;

  return (
    <Link
      to={memberURL}
      sx={{flex: 1, width: '100%', height: '100%'}}
      onClick={(e) => e.stopPropagation()}>
      <Stack sx={{justifyContent: 'center', height: '100%'}}>
        <Typography
          noWrap
          sx={{
            fontWeight: Theme.typography.weight.bold,
            fontSize: Theme.typography.size.s2,
            color: Theme.colorPalette.link,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}>
          {member.name}
        </Typography>
        <Typography
          noWrap
          sx={{
            color: Theme.colorPalette.darkest,
            fontSize: Theme.typography.size.s2,
          }}>
          {identity.name}
        </Typography>
        <Typography
          noWrap
          sx={{
            color: Theme.colorPalette.darkest,
            fontSize: Theme.typography.size.s2,
          }}>
          <Box component="span" sx={{fontWeight: 800}}>
            Last Login:{' '}
          </Box>
          {lastLogin
            ? moment(lastLogin).format('MMM D, YYYY')
            : 'Has not logged in'}
        </Typography>
        <Typography
          noWrap
          sx={{
            color: Theme.colorPalette.darkest,
            fontSize: Theme.typography.size.s2,
          }}>
          <Box component="span" sx={{fontWeight: 800}}>
            Group:{' '}
          </Box>
          {groups.join(', ')}
        </Typography>
      </Stack>
    </Link>
  );
};
