// @flow
import React from 'react';
import type {Node} from 'react';
import {Box, Tooltip} from '@wellstone-solutions/web';
import {Transforms} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';
import type {
  ApiOrganizationType,
  UIOrganizationType,
} from 'modules/organization/types';

export const Branding = (): Node => {
  const {meStore} = useStores();

  const {
    organization: apiOrganization,
  }: {organization: ApiOrganizationType} = meStore.me.membership;

  const uiOrganization: UIOrganizationType = Transforms.toUIObject(
    apiOrganization,
  );

  const {data: orgData} = uiOrganization;

  const defaultLogoUrl = `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/organizations/logos/PathfinderLeaf_RGB.png`;
  const logoUrl = uiOrganization.imageUrl ?? defaultLogoUrl;
  const logoAltText = orgData?.logoAltText ?? uiOrganization.name;

  return (
    <Box style={styles.logoContainer} data-testid="navigation-branding">
      <Tooltip title={logoAltText} placement="bottom-start">
        <img
          src={logoUrl}
          alt={logoAltText}
          style={styles.logo}
          data-testid="navigation-branding-logo"
        />
      </Tooltip>
    </Box>
  );
};

const styles = {
  logoContainer: {
    textAlign: 'left',
    width: 200,
    height: 100,
  },
  logo: {
    width: 200,
    height: 76,
    objectPosition: 'left center',
    objectFit: 'contain',
  },
};
