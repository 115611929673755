// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Stack,
  Checkbox,
  Typography,
  Select,
  Icon,
  IconNames,
  TextField,
  Paper,
  Chip,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {UIHabitType} from '@wellstone-solutions/common/models/rest/habit/types';
import {RemoteIcon} from 'components/RemoteIcon';

type PropsType = {
  habit: UIHabitType & {
    checked: boolean,
  },
  handleCheckboxChange: (id: string, isChecked: boolean) => void,
  handleFrequencyChange: (id: string, newFrequency: string) => void,
  handleIntervalChange: (id: string, newInterval: number) => void,
};

const frequencyOptions = [
  {label: 'Daily', value: 'daily', icon: IconNames.Clock},
  {label: 'Weekly', value: 'weekly', icon: IconNames.ReportColumns},
  {label: 'Monthly', value: 'monthly', icon: IconNames.Calendar},
];

export const HabitListItem = ({
  habit,
  handleCheckboxChange,
  handleFrequencyChange,
  handleIntervalChange,
}: PropsType): Node => {
  return (
    <Paper>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={styles.container}>
        <Stack direction="column" sx={styles.leftSection}>
          {habit.habitType && (
            <Chip
              size="small"
              variant="solid"
              color={habit.habitType === 'personal' ? 'secondary' : 'primary'}
              label={habit.habitType === 'personal' ? 'Personal' : 'Assigned'}
            />
          )}

          <Stack direction="row" alignItems="center" sx={styles.habitDetails}>
            <Checkbox
              checked={habit.checked}
              disabled={habit.habitType === 'personal'}
              onChange={(e) => handleCheckboxChange(habit.id, e.target.checked)}
              sx={styles.checkbox}
            />
            <Box>
              <RemoteIcon
                name={habit.name}
                disableBorder={true}
                path={'/icons/habits'}
                size={60}
              />
            </Box>
            <Box>
              <Typography sx={styles.habitName}>{habit.name}</Typography>
              {habit.translation && (
                <Typography sx={styles.translation}>
                  {habit.translation}
                </Typography>
              )}
            </Box>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" sx={styles.actionsContainer}>
          <Select
            label="Frequency"
            items={frequencyOptions.map((option) => ({
              label: (
                <Box sx={styles.selectOption}>
                  <Icon name={option.icon} size={16} />
                  <Typography sx={styles.optionText}>{option.label}</Typography>
                </Box>
              ),
              value: option.value,
            }))}
            value={habit.frequency}
            onChange={(e) => handleFrequencyChange(habit.id, e.target.value)}
            disabled={habit.habitType === 'personal'}
            sx={styles.select}
          />

          <TextField
            sx={styles.intervalInput}
            value={habit.interval}
            onChange={(e) =>
              handleIntervalChange(habit.id, Number(e.target.value))
            }
            label="Interval"
            type="number"
            disabled={habit.habitType === 'personal'}
            inputProps={{min: 1}}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

const styles = {
  container: {
    padding: Theme.spacing.mini,
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing.small,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '60%',
  },
  habitDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing.mini,
  },
  checkbox: {
    marginRight: Theme.spacing.mini,
  },
  habitName: {
    fontSize: Theme.spacing.large,
    fontWeight: 'bold',
  },
  translation: {
    fontSize: Theme.spacing.medium,
    marginTop: Theme.spacing.micro,
    color: Theme.colorPalette.mediumDark,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing.small,
    width: '40%',
    justifyContent: 'space-between',
  },
  select: {
    width: '150px',
    marginRight: Theme.spacing.micro,
  },
  selectOption: {
    display: 'flex',
    alignItems: 'center',
  },
  optionText: {
    marginLeft: '4px',
  },
  intervalInput: {
    width: '100px',
  },
};
