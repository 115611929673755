// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Hooks, Models, Validation} from '@wellstone-solutions/common';
import {Wizard} from 'components/Wizard';
import {useStores} from 'hooks/useStores';
import {ROLES} from 'utils/Permissions';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import type {WizardStepType} from 'components/Wizard';
import {STAFF_PERMISSIONS} from 'constants/Permissions';
import {
  ConfirmationStep,
  EmailStep,
  IntegrationStep,
  MemberInfoStepLoader,
  GroupsStep,
  ProgramsStep,
} from './steps';
import {DEFAULT_TITLE} from './constants';
import {ENTITY_LEVEL} from 'modules/rbac';

type PropsType = {
  onCancel: () => void,
};

const wizardSchema = {
  ...Models.Staff.schema,
  gender: Validation.isRequired,
  identifiesAs: Validation.isRequired,
  birthdate: Validation.isOptional,
  programs: (value) => value.find((program) => !program.existing),
  groups: (value) => value.find((group) => !group.existing),
};

const AUTH_PERMISSIONS = [STAFF_PERMISSIONS.read, STAFF_PERMISSIONS.write];

export const CreateWizard = ({onCancel}: PropsType): Node => {
  const {memberStore, meStore, staffStore, RBACStore} = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const orgIntegration = meStore.ehrIntegrationOption;
  const [requiresEmailEntry, setRequiresEmailEntry] = useState(!orgIntegration);

  const integrationLabel = orgIntegration?.name;

  const requiresGroups = (values) =>
    RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.group, values.role, true);

  const steps: Array<WizardStepType> = [
    requiresEmailEntry
      ? {
          name: 'EmailStep',
          subtitle: (form) =>
            `Enter the staff member's email address so we can verify it in Bridge.<br />${
              form.values.name.length === 0 // only show tip if name is empty as it might've been set by EHR
                ? "**Quick Tip!** You will also need the person's full name and birthdate."
                : ''
            }`,
          component: EmailStep,
          disableFooter: !!orgIntegration,
          data: {
            setRequiresEmailEntry,
            orgIntegration: orgIntegration,
          },
          isComplete: (formValues: any) => wizardSchema.email(formValues.email),
        }
      : {
          name: 'IntegrationStep',
          subtitle: () =>
            `You can create a profile in Bridge by using a staff member's ${integrationLabel} identifier.`,
          component: IntegrationStep,
          disableFooter: true,
          data: {
            setRequiresEmailEntry,
            orgIntegration: orgIntegration,
          },
          isComplete: (formValues: any) =>
            formValues.externalRelationshipId?.length > 0,
        },
    {
      name: 'MemberInfoStep',
      subtitle: () => 'All fields are required.',
      component: MemberInfoStepLoader,
      // Not checking birthdate, existing users may not have one set
      isComplete: (formValues: any) => {
        // If existing user, always be done
        if (formValues.id) {
          return true;
        }

        return (
          wizardSchema.email(formValues.email) &&
          wizardSchema.name(formValues.name) &&
          wizardSchema.identifiesAs(formValues.identifiesAs) &&
          wizardSchema.gender(formValues.gender) &&
          wizardSchema.role(formValues.role) &&
          // Cannot add a patient
          formValues.role !== ROLES.patient &&
          Validation.isRequired(formValues.birthdate)
        );
      },
      title: (form) =>
        form.values?.id ? 'Update existing staff member' : DEFAULT_TITLE,
    },
    {
      name: 'ProgramsStep',
      subtitle: (form) =>
        `**Next, assign ${form.values.name} to one or more Programs.** Programs are specific clinics, locations, or departments that focus on a specific area of recovery.`,
      component: ProgramsStep,
      isComplete: (formValues: any) =>
        wizardSchema.programs(formValues.programs),
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
    {
      name: 'GroupsStep',
      subtitle: (form) =>
        `**Now, assign ${form.values.name} to one or more Groups.** A group is a small set of members associated with a specific staff member or specific section of services.`,
      component: GroupsStep,
      isComplete: (formValues: any) =>
        requiresGroups(formValues)
          ? wizardSchema.groups(formValues.groups)
          : true,
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
    {
      name: 'ConfirmationStep',
      subtitle: (form) =>
        `**You’re almost done!** Review ${form.values.name}'s details, then hit Save. If you need to make edits in the future, you can do that on ${form.values.name}’s profile page.`,
      component: ConfirmationStep,
      data: {
        orgIntegration: orgIntegration,
      },
      isComplete: (formValues: any) => true,
      submitText: (form) => 'Save',
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
  ];

  const form = Hooks.useForm({
    initialValues: {
      birthdate: '',
      gender: '',
      identifiesAs: '',
      id: '',
      name: '',
      email: '',
      role: '',
      programs: [],
      groups: [],
    },
    schema: wizardSchema,
    // Not used here
    onSubmit: () => undefined,
  });

  const onSubmit = async () => {
    setIsSubmitting(true);

    let {
      programs,
      groups,
      id,
      externalRelationshipId,
      ...uiApiInput
    } = form.values;

    let shouldCreateAuths = true;

    // Adjust messaging to when we aren't adding a new staff member
    // call out that we simply added the to programs and groups
    const createMessage = !id
      ? 'Created new staff member.'
      : 'Added staff member to program(s) and group(s).';

    let staffId;
    // Create staff member if not already created
    if (!id) {
      const result = await staffStore.createStaffMember(
        Models.Staff.toApiInput(uiApiInput),
      );

      shouldCreateAuths = result.isSuccess;
      // Capture new staff member id if created ok
      if (result.isSuccess) {
        staffId = result.data.id;
        id = staffId;
      } else {
        // $FlowIgnore - detail is coming back from API in this case
        const errorDetail = String(result.data?.detail || '');
        showAlert(
          `Failed to create new staff member. ${errorDetail}`,
          ALERT_TYPES.ERROR,
        );
        onCancel();
        setIsSubmitting(false);
      }
    }

    if (shouldCreateAuths) {
      // Let's add program and group authorizations now

      const newPrograms = programs
        .filter(({existing}) => !existing)
        .map((program) => program);
      const newGroups = groups
        .filter(({existing}) => !existing)
        .map((group) => group);

      const authResults = await Promise.all([
        ...newPrograms.map((program) =>
          staffStore.createAuthorization({
            staffId: id,
            authorizationInput: Models.Authorization.fromProgramToApiInput(
              program,
              AUTH_PERMISSIONS,
            ),
          }),
        ),
        ...newGroups.map((group) =>
          staffStore.createAuthorization({
            staffId: id,
            authorizationInput: Models.Authorization.fromGroupToApiInput(
              group,
              AUTH_PERMISSIONS,
            ),
          }),
        ),
      ]);

      const authSuccess = authResults.some(
        (authResult) => authResult.isSuccess,
      );

      if (authSuccess) {
        showAlert(createMessage);
      } else {
        showAlert(
          'Failed to add new staff member to requested programs and/or groups.',
          ALERT_TYPES.ERROR,
        );

        onCancel();
      }
    }

    // Only create EHR integration if we have both external ID and created admission successfully (ie. have a staffId)
    const shouldCreateEHRIntegration =
      staffId && form.values?.externalRelationshipId?.length > 0;

    if (shouldCreateEHRIntegration) {
      const response = await memberStore.createEHRIntegration({
        // $FlowIgnore - staffId check already avoids undefined case
        memberId: staffId,
        externalRelationshipId: form.values.externalRelationshipId,
      });

      if (!response.isSuccess) {
        showAlert(
          `Failed to add ${integrationLabel} integration.`,
          ALERT_TYPES.error,
        );
      }
    }

    setIsSubmitting(false);
    onCancel();
  };

  return (
    <Wizard
      isSubmitting={isSubmitting}
      form={form}
      steps={steps}
      title={DEFAULT_TITLE}
      onCancel={onCancel}
      onWizardSubmit={onSubmit}
    />
  );
};
