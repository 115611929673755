// @flow
import React, {useRef} from 'react';
import type {Node} from 'react';
import {hasFlag, TMP_PEERS_DOCUMENTATION} from 'constants/FeatureFlags';
import type {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';
import Tag from 'react-bulma-components/lib/components/tag';
import {NavLink, useNavigate} from 'react-router-dom';
import {
  Stack,
  Typography,
  Icon,
  IconNames,
  Box,
  GridActionsCellItem,
} from '@wellstone-solutions/web';
import {Transforms, Theme} from '@wellstone-solutions/common';
import type {UIMemberType} from '@wellstone-solutions/common';
import {Palette} from 'palette';
import {styleTag} from 'utils/Styler';
import {getIdentity} from 'utils/Forms';
import {toRelativeDate} from 'utils/dateHelpers';
import {getSeverityColor} from 'utils/Charts';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';
import EditMemberModal from 'components/modal/member/EditModal';
import CommunitySwitch from 'components/forms/components/CommunitySwitch';
import {ResendInvitationEmail} from 'modules/member';
import {
  ALL_MEMBERS,
  LAST_INVITATION_REINVITE_MINUTES,
  PATIENT_CREATED_REINVITE_MINUTES,
} from '../../constants';
import {NavigationCell, ListCell} from 'modules/datagrid';

import {CreateDocumentationAction} from '../../components/MemberListV2/CreateDocumentationAction';
import {MemberProfileCell} from '../../components/MemberListV2/MemberProfileCell';

type PropsType = {
  refetch?: () => void,
  programId?: string | null,
  groupId?: string | null,
  onResendInvitationAction: (member: UIMemberType) => void,
};

type ColumnsMapType = {
  name: GridColDef<UIMemberType>,
  nameLink: GridColDef<UIMemberType>,
  nameLinkGender: GridColDef<UIMemberType>,
  lastCheckIn: GridColDef<UIMemberType>,
  birthdate: GridColDef<UIMemberType>,
  identifiesAs: GridColDef<UIMemberType>,
  groups: GridColDef<UIMemberType>,
  groupsList: GridColDef<UIMemberType>,
  groupStartDate: GridColDef<UIMemberType>,
  groupEndDate: GridColDef<UIMemberType>,
  groupDischargeDate: GridColDef<UIMemberType>,
  hasCommunityAccess: GridColDef<UIMemberType>,
  programStartDate: GridColDef<UIMemberType>,
  programEndDate: GridColDef<UIMemberType>,
  lastOpened: GridColDef<UIMemberType>,
  actions: GridColDef<UIMemberType>,
  actionsList: GridColDef<UIMemberType>,
  ehr: GridColDef<UIMemberType>,
  memberDescription: GridColDef<UIMemberType>,
};

type ValueType<T> = {
  value: T | null,
};

const findProgramAdmission = (member, programId) => {
  if (!programId || programId === ALL_MEMBERS) {
    return null;
  }

  return member.admissions.find(
    (admission) => admission.program.id === programId,
  );
};

const findGroupAdmission = (member, groupId) => {
  if (!groupId) {
    return null;
  }

  const admissions = member.groupAdmissions.filter(
    ({group}) => group.id === groupId,
  );

  // $FlowFixMe
  const activeAdmission = admissions.find((adm) => adm.discharge_date === null);

  return activeAdmission || admissions[0];
};

const noop = () => {};

export const useMemberColumnMap = ({
  refetch = noop,
  onResendInvitationAction,
  programId = null,
  groupId = null,
}: PropsType): ColumnsMapType => {
  const {messageStore, meStore, organizationStore, RBACStore} = useStores();
  const navigate = useNavigate();

  const dateCompare = useRef({
    tomorrow: moment().add(1, 'days'),
    nextWeek: moment().add(7, 'days'),
  });

  const dateClassName = ({field, value}: {field: string, value?: Date}) => {
    if (!value) {
      return;
    }

    const date = moment(value);
    if (date.isBefore(dateCompare.current.tomorrow, 'day')) {
      return `${field} date-cell-before`;
    } else if (date.isBefore(dateCompare.current.nextWeek, 'day')) {
      return `${field} date-cell-soon`;
    } else {
      return `${field} date-cell-inactive`;
    }
  };

  const handleResendInvitation = (member) => {
    onResendInvitationAction(member);
  };

  const orgEHRIntegration = meStore.ehrIntegrationOption;

  const NameCell = ({member}): Node => {
    const hasMessage = messageStore.messages.some(
      (message) => message.user === member.user.id,
    );

    return (
      <div style={styles.hideOverflow}>
        <NavLink
          renderas="a"
          to={'/members/' + member.id}
          className={({isActive}) =>
            isActive ? 'active-link' : 'inactive-link'
          }
          style={styles.bold}>
          {member.name}
          {hasMessage && (
            <Icon
              name={IconNames.ChatBubbleEmpty}
              size={16}
              color={Palette.RELATIONSHIP}
            />
          )}
        </NavLink>
      </div>
    );
  };
  const NameGenderCell = ({member}): Node => {
    const identity = getIdentity(member.identifiesAs);

    return (
      <NavigationCell
        to={'/members/' + member.id}
        text={member.name}
        subText={identity.name}
      />
    );
  };
  const MemberDescription = ({member}): Node => {
    return <MemberProfileCell member={member} />;
  };

  const LastCheckInCell = ({member}): Node => {
    const lastCheckInDate = member.lastCheckIn?.created;

    if (!lastCheckInDate) {
      return <Tag>No check-ins</Tag>;
    }

    const daysSince = moment().diff(moment(lastCheckInDate), 'days');

    return (
      <Tag
        style={{
          ...styles.checkIn,
          backgroundColor: getSeverityColor(daysSince, 20),
        }}>
        {toRelativeDate(lastCheckInDate)}
      </Tag>
    );
  };

  const IdentifiesAsCell = ({member}): Node => {
    const identity = getIdentity(member.identifiesAs);
    return (
      <Tag
        color="info"
        style={styleTag(identity.name, true)}
        title={identity.name}>
        {identity.name}
      </Tag>
    );
  };

  const GroupsCell = ({member}): Node => {
    const groupTags = (member.groupAdmissions || [])
      // $FlowFixMe
      .filter((adm) => !adm.discharge_date)
      .map((admission, g) => (
        <Tag
          key={g}
          title={admission.group.name}
          style={{
            ...styleTag(
              organizationStore.getGroupCategoryById(
                // $FlowFixMe
                admission.group.category_id,
              ).name,
              true,
            ),
            ...styles.groupTag,
          }}>
          <Typography noWrap sx={{fontSize: 'inherit', fontWeight: 'inherit'}}>
            {admission.group.name}
          </Typography>
        </Tag>
      ));

    return (
      <Stack direction="row" flexWrap="wrap" style={styles.groupStack}>
        {groupTags}
      </Stack>
    );
  };

  const GroupsListCell = ({member}): Node => {
    const items = (member.groupAdmissions || [])
      .filter((adm) => !adm.discharge_date)
      .map((admission) => admission.group?.name)
      .filter(Boolean);

    return items.length > 0 ? <ListCell items={items} /> : <Box>No Groups</Box>;
  };

  const ActionCell = ({member}): Node => (
    <Stack direction="column" sx={{py: 1, minHeight: '3em'}}>
      {RBACStore.hasAccess(AccessControl.members.editMember) && (
        <EditMemberModal
          member={Transforms.toApiObject(member)}
          onCloseModal={refetch}
        />
      )}
      {RBACStore.hasAccess(AccessControl.members.sendReinvite) &&
        !member.lastOpenedApp && (
          <ResendInvitationEmail
            id={member.id}
            memberCreated={member.created}
            // $FlowFixMe
            lastInviteSent={member.inviteSent?.created}
            name={member.name}
            email={member.user.email}
            organizationId={meStore.organizationId}
          />
        )}
    </Stack>
  );
  const getRowActions = ({row}): Array<Node> => {
    const memberId = row.id;
    const lastInviteSent = row.inviteSent?.created;
    const lastSent = lastInviteSent ? moment(lastInviteSent) : null;
    const canSendInvitation = RBACStore.hasAccess(
      AccessControl.members.editMember,
    );
    const now = moment();
    const isNewMember =
      now.diff(moment(row.created), 'minutes') <
      PATIENT_CREATED_REINVITE_MINUTES;
    const isRecentLastSent =
      lastSent &&
      now.diff(lastSent, 'minutes') < LAST_INVITATION_REINVITE_MINUTES;
    const canBeReinvited =
      canSendInvitation &&
      !row.lastOpenedApp &&
      !isNewMember &&
      !isRecentLastSent;
    const canCreateDocumentation = hasFlag(
      TMP_PEERS_DOCUMENTATION,
      meStore.features,
    );
    return [
      <GridActionsCellItem
        label="View profile"
        key="viewProfile"
        icon={
          <Icon
            name={IconNames.ProfileCircle}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        showInMenu={true}
        onClick={() => {
          navigate(`/members/${memberId}`);
        }}
        disabled={false}
      />,
      <GridActionsCellItem
        label="Resend Invitation"
        key="resendInvitation"
        icon={
          <Icon
            name={IconNames.Mail}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        showInMenu={true}
        onClick={() => handleResendInvitation(row)}
        disabled={!canBeReinvited}
      />,
      ...(canCreateDocumentation
        ? [
            <GridActionsCellItem
              icon={<CreateDocumentationAction member={row} disabled={false} />}
              label=""
              key="createDocumentation"
              showInMenu={true}
              disabled={false}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />,
          ]
        : []),
    ];
  };

  const CommunityAccessCell = ({member}): Node => {
    return meStore.inMyPrimaryGroups(member) ? (
      <CommunitySwitch member={Transforms.toApiObject(member)} />
    ) : (
      <div
        style={{
          ...styles.communityIndicator,
          ...{
            backgroundColor: member.hasCommunityAccess
              ? Palette.SUCCESS
              : Palette.DANGER,
          },
        }}>
        {member.hasCommunityAccess ? 'Y' : 'N'}
      </div>
    );
  };

  const EHRCell = ({member}: {member: UIMemberType}): Node => {
    const {integrations} = member;
    const hasEHRConnection = integrations?.length > 0;
    const externalID =
      integrations?.length > 0 ? integrations[0]?.externalRelationshipId : '';

    return hasEHRConnection ? (
      <Typography>{externalID}</Typography>
    ) : (
      <Typography
        sx={{color: Theme.colorPalette.red, fontSize: 14, fontWeight: 'bold'}}>
        Not connected
      </Typography>
    );
  };

  const columnMap = {
    name: {
      field: 'name',
      headerName: 'Name',
      minWidth: 180,
    },
    lastCheckIn: {
      field: 'lastCheckIn',
      headerName: 'Last Check In',
      type: 'dateTime',
      valueGetter: ({row: member}) =>
        member.lastCheckIn ? moment(member.lastCheckIn.created).toDate() : null,
      minWidth: 140,
      renderCell: ({row: member}) => <LastCheckInCell member={member} />,
    },
    nameLink: {
      field: 'name',
      headerName: 'Name',
      minWidth: 140,
      flex: 1,
      renderCell: ({row: member}) => <NameCell member={member} />,
    },
    nameLinkGender: {
      field: 'name',
      headerName: 'Name',
      minWidth: 140,
      flex: 1.5,
      renderCell: ({row: member}) => <NameGenderCell member={member} />,
    },
    memberDescription: {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1.5,
      renderCell: ({row: member}) => <MemberDescription member={member} />,
    },
    birthdate: {
      field: 'birthdate',
      type: 'date',
      headerName: 'Birthdate',
      flex: 0.5,
      minWidth: 120,
      valueGetter: ({row: member}) =>
        member.birthdate ? moment(member.birthdate).toDate() : null,
      valueFormatter: ({value}: ValueType<Date>) =>
        value ? moment(value).format('MMM D, YYYY') : 'Not set',
    },
    identifiesAs: {
      field: 'identifiesAs',
      headerName: 'Identifies As',
      flex: 1,
      minWidth: 160,
      renderCell: ({row: member}) => <IdentifiesAsCell member={member} />,
    },
    groups: {
      field: 'groups',
      headerName: 'Groups',
      minWidth: 200,
      sortable: false,
      flex: 2,
      renderCell: ({row: member}) => <GroupsCell member={member} />,
    },
    groupsList: {
      field: 'groups',
      headerName: 'Groups',
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: ({row: member}) => <GroupsListCell member={member} />,
    },
    groupStartDate: {
      field: 'startDate',
      headerName: 'Group Start Date',
      valueGetter: ({row: member}) => {
        const group = findGroupAdmission(member, groupId);
        // $FlowFixMe
        return group ? moment(group.start_date).toDate() : null;
      },
      valueFormatter: ({value}: ValueType<Date>) =>
        value && moment(value).format('MMM D, YYYY'),
      minWidth: 180,
    },
    groupEndDate: {
      field: 'endDate',
      type: 'date',
      valueGetter: ({row: member}) => {
        const group = findGroupAdmission(member, groupId);
        // $FlowFixMe
        return group ? moment(group.end_date).toDate() : null;
      },
      valueFormatter: ({value}: ValueType<Date>) =>
        value && moment(value).format('MMM D, YYYY'),
      headerName: 'Group End Date',
      minWidth: 180,
    },
    groupDischargeDate: {
      field: 'dischargeDate',
      headerName: 'Group Discharge',
      type: 'date',
      valueGetter: ({row: member}) => {
        const group = findGroupAdmission(member, groupId);
        // $FlowFixMe
        return group ? moment(group.discharge_date).toDate() : null;
      },
      valueFormatter: ({value}: ValueType<Date>) =>
        value && moment(value).format('MMM D, YYYY'),
      minWidth: 180,
    },
    hasCommunityAccess: {
      field: 'hasCommunityAccess',
      type: 'boolean',
      valueGetter: ({row: member}) => member.hasCommunityAccess,
      renderCell: ({row: member}) => <CommunityAccessCell member={member} />,
      headerName: 'Peer-to-peer Access',
      minWidth: 150,
    },
    programStartDate: {
      field: 'startDate',
      type: 'date',
      headerName: 'Start Date',
      minWidth: 115,
      flex: 0.5,
      valueGetter: ({row: member}) => {
        const programAdmission = findProgramAdmission(member, programId);
        return programAdmission
          ? // $FlowFixMe
            moment(programAdmission.start_date).toDate()
          : null;
      },
      valueFormatter: ({value}: ValueType<Date>) =>
        value && moment(value).format('MMM D, YYYY'),
      cellClassName: dateClassName,
    },
    programEndDate: {
      field: 'endDate',
      type: 'date',
      headerName: 'End Date',
      minWidth: 115,
      flex: 0.5,
      valueGetter: ({row: member}) => {
        const programAdmission = findProgramAdmission(member, programId);
        return programAdmission
          ? // $FlowFixMe
            moment(programAdmission.end_date).toDate()
          : null;
      },
      valueFormatter: ({value}: ValueType<Date>) =>
        value && moment(value).format('MMM D, YYYY'),
      cellClassName: dateClassName,
    },
    lastOpened: {
      field: 'lastOpenedApp',
      type: 'dateTime',
      headerName: 'Last Login',
      minWidth: 140,
      flex: 0.5,
      valueGetter: ({row: member}) =>
        // $FlowFixMe
        member.lastOpenedApp
          ? moment(member.lastOpenedApp.created).toDate()
          : null,
      valueFormatter: ({value}: ValueType<Date>) =>
        value ? moment(value).format('MMM D, YYYY') : 'Has not logged in',
      cellClassName: ({value}) => !value && 'date-cell-inactive',
    },
    actions: {
      field: 'id',
      headerName: 'Actions',
      sortable: false,
      flex: 0.5,
      minWidth: 200,
      renderCell: ({row: member}) => <ActionCell member={member} />,
    },

    actionsList: {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      maxWidth: 90,
      getActions: ({row}) => getRowActions({row}),
      valueGetter: ({row}) => row.name,
    },

    ehr: {
      field: 'ehr',
      headerName: orgEHRIntegration?.name ?? 'EHR System',
      minWidth: 200,
      renderCell: ({row: member}) => <EHRCell member={member} />,
    },
  };

  return columnMap;
};

const styles = {
  communityIndicator: {
    borderRadius: 12,
    textAlign: 'center',
    lineHeight: '26px',
    fontWeight: 'bold',
    height: 24,
    width: 24,
    fontSize: 12,
    color: 'white',
  },
  bold: {
    fontWeight: 'bold',
  },
  checkIn: {
    color: Theme.colorPalette.lightest,
    textTransform: 'capitalize',
  },
  hideOverflow: {
    overflow: 'hidden',
  },
  groupStack: {
    maxWidth: '100%',
  },
  groupTag: {
    maxWidth: '100%',
    justifyContent: 'flex-start',
  },
};
