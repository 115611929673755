// @flow
import React, {useState, useCallback} from 'react';
import type {Node} from 'react';
import {
  Button,
  Stack,
  Typography,
  TextField,
  Progress,
  Avatar,
  Icon,
  IconNames,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@wellstone-solutions/web';
import {Models, Theme} from '@wellstone-solutions/common';
import type {UIMemberType} from '@wellstone-solutions/common';

import {ALERT_TYPES, showAlert} from 'utils/showAlert';

const {Member} = Models;

type PropsType = {
  member?: UIMemberType,
  organizationId: string,
  open: boolean,
  onComplete: ({success: boolean}) => void,
};

export const ResendInvitation = ({
  member,
  open,
  onComplete,
  organizationId,
}: PropsType): Node => {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const onActionComplete = useCallback(
    ({success}) => {
      setIsSending(false);
      setMessage('');
      onComplete({success});
    },
    [onComplete],
  );

  const sendEmail = async () => {
    try {
      setIsSending(true);
      const result = await Member.sendInvitation(
        {
          orgId: organizationId,
          id: member.id,
        },
        {message},
      );
      if (result.isSuccess) {
        showAlert(`Re-sent invitation to ${member.name}.`);
        onActionComplete({success: true});
      } else {
        showAlert(
          'Could not resend invitation, please try again',
          ALERT_TYPES.ERROR,
        );
        onActionComplete({success: false});
      }
    } catch (err) {
      showAlert('An unexpected error occurred.', ALERT_TYPES.ERROR);
      onActionComplete({success: false});
    }
  };

  return member ? (
    <Dialog
      onClose={onActionComplete}
      open={open}
      sx={styles.dialog}
      data-testid="member-resend-invite">
      <DialogTitle sx={{padding: 0}}>
        <Stack sx={{marginBottom: 2}}>
          <Stack
            direction="row"
            sx={{justifyContent: 'space-between', marginBottom: 2}}>
            <Avatar sx={styles.avatar}>
              <Icon
                name={IconNames.SendSolid}
                size={24}
                color={Theme.colorPalette.primary}
              />
            </Avatar>
            <Button
              color="primary"
              onClick={() => onActionComplete({success: false})}
              sx={{padding: 0, minWidth: 32, height: 32}}>
              <Icon
                name={IconNames.Xmark}
                size={28}
                color={Theme.colorPalette.primary}
              />
            </Button>
          </Stack>
          <Typography variant="h5" sx={{fontWeight: 400}}>
            Resend invitation
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Typography
          variant="body2"
          sx={{mb: 3, color: Theme.colorPalette.dark, fontSize: '1rem'}}>
          Send an email to {member.name} ({member.user?.email}) that contains
          directions for downloading the Companion app.
        </Typography>
        <Typography sx={styles.textField}>
          <TextField
            id="inviteMessage"
            label="Personal Message (optional)"
            multiline
            maxRows={4}
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            sx={{width: '100%'}}
          />
        </Typography>
        <Typography variant="body" sx={styles.textFieldDescription}>
          The Personal message will appear in the email invitation.
        </Typography>
      </DialogContent>
      <DialogActions sx={{padding: 0}}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'end',
            gap: 2,
          }}>
          <Button
            onClick={() => {
              onActionComplete({success: false});
            }}
            color="primary"
            style={{...styles.button, justifyContent: 'center'}}>
            Cancel
          </Button>
          <Button
            onClick={sendEmail}
            color="primary"
            variant="contained"
            disabled={isSending}
            sx={styles.button}
            startIcon={
              isSending ? (
                <Progress
                  size="1.2em"
                  color="inherit"
                  data-testid="loading-spinner"
                />
              ) : (
                <Icon name={IconNames.SendSolid} size={24} color="inherit" />
              )
            }>
            {' '}
            Send
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
};

const styles = {
  dialog: {
    '& .MuiPaper-root': {
      width: '537px',
      borderRadius: 3,
      padding: 3,
    },
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: `${Theme.colorPalette.primary}33`,
  },
  content: {
    padding: 0,
    paddingBottom: Theme.spacing.small,
  },
  text: {
    fontSize: 18,
    color: Theme.colorPalette.darkest,
    mb: 2,
  },
  textField: {
    mb: '3px',
  },
  textFieldDescription: {
    fontSize: 12,
    color: Theme.colorPalette.dark,
    paddingX: 2,
  },
  button: {
    fontSize: 16,
    textTransform: 'none',
    borderRadius: '20px',
    px: 3,
  },
};
